import {
  GET_COMPANY,
  GET_COMPANY_INFO,
  QUERY_COMPANY_FILES,
  QUERY_COMPANY_PRIORITIES,
  QUERY_COMPANY_CATEGORIES
} from "../../actions/company/CompanyActions";

const Company = (state = { priorities: [], categories: [], isLoading: true }, action) => {
  switch (action.type) {
    case GET_COMPANY + "_FULFILLED":
      return {
        ...state,
        company: action.payload.body
      };

    case GET_COMPANY_INFO + "_FULFILLED":
      return {
        ...state,
        info: action.payload.body,
        isLoading: false
      };

    case GET_COMPANY_INFO + "_PENDING":
      return {
        ...state,
        isLoading: true
      };

    case GET_COMPANY_INFO + "_REJECTED":
      return {
        ...state,
        isLoading: false
      };

    case QUERY_COMPANY_FILES + "_FULFILLED":
      return {
        ...state,
        files: action.payload.body
      };

    case QUERY_COMPANY_PRIORITIES + "_FULFILLED":
      return {
        ...state,
        priorities: action.payload.body
      };

    case QUERY_COMPANY_CATEGORIES + "_FULFILLED":
      return {
        ...state,
        categories: action.payload.body
      };

    default:
      return state || {};
  }
};

export default Company;
