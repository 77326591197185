import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_COMMENTS = "QUERY_COMPANY_COMMENTS";

export const queryComments = (companyId, params) => {
  return {
    type: QUERY_COMPANY_COMMENTS,
    payload: APIUtils.get(`/companies/${companyId}/comments?${qs.stringify(params)}`)
  };
};
