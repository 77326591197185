import {
  QUERY_COMPANY_POSTS
} from "../../../actions/company/messages/CompanyMessageActions";

const Message = (state = {posts: []}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_POSTS + "_FULFILLED":
      return {
        ...state,
        posts: action.payload.body
      };

    default:
      return state || {};
  }
};

export default Message;
