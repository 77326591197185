import {
  QUERY_COMPANY_CATEGORIES,
  GET_COMPANY_CATEGORIES
} from "../../../actions/company/categories/CompanyCategoryActions";

const Category = (state = {categories: [], category: null}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_CATEGORIES + "_FULFILLED":
      return {
        ...state,
        categories: action.payload.body
      };
    case GET_COMPANY_CATEGORIES + "_FULFILLED":
      return {
        ...state,
        category: action.payload.body
      };

    default:
      return state || {};
  }
};

export default Category;
