import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_POSTS = "QUERY_COMPANY_POSTS";

export const queryPosts = (companyId, params) => {
  return {
    type: QUERY_COMPANY_POSTS,
    payload: APIUtils.get(`/companies/${companyId}/posts?${qs.stringify(params)}`)
  };
};
