import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_GROUPS = "QUERY_COMPANY_GROUPS";
export const QUERY_COMPANY_PORTALS = "QUERY_COMPANY_PORTALS";

export const queryGroups = (companyId, params) => {
  return {
    type: params?.type === "HIDDEN"?QUERY_COMPANY_GROUPS:QUERY_COMPANY_PORTALS,
    payload: APIUtils.get(`/companies/${companyId}/groups?${qs.stringify(params)}`)
  };
};
