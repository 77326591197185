import {
  QUERY_COMPANY_PUBLICATION_FILES,
  GET_COMPANY_PUBLICATION_FILE_PREVIEW
} from "../../../actions/company/publications/CompanyPublicationFileActions";

const PublicationFile = (state = {files: []}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_PUBLICATION_FILES + "_FULFILLED":
      return {
        ...state,
        files: action.payload.body
      };
    case GET_COMPANY_PUBLICATION_FILE_PREVIEW + "_FULFILLED":
      return {
        ...state,
        filePreview: action.payload.body
      };

    default:
      return state || {};
  }
};

export default PublicationFile;
