import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_PUBLICATION_POSTS = "QUERY_COMPANY_PUBLICATION_POSTS";

export const queryPublicationPosts = (companyId, publicationId, params = {}) => {
	return {
		type: QUERY_COMPANY_PUBLICATION_POSTS,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/${publicationId}/posts?${qs.stringify(params)}`
		),
	};
};
