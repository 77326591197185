import {
  QUERY_COMPANY_PUBLICATION_ESIGNS
} from "../../../actions/company/publications/CompanyPublicationEsignActions";

const PublicationEsign = (state = { esigns: [] }, action) => {
  switch (action.type) {
    case QUERY_COMPANY_PUBLICATION_ESIGNS + "_FULFILLED":
      return {
        ...state,
        esigns: action.payload.body
      };

    default:
      return state || {};
  }
};

export default PublicationEsign;
