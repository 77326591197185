import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_CHECKLISTS = "QUERY_COMPANY_CHECKLISTS";
export const CREATE_COMPANY_CHECKLIST = "CREATE_COMPANY_CHECKLIST";
export const DELETE_COMPANY_CHECKLIST = "DELETE_COMPANY_CHECKLIST";

export const queryChecklists = (companyId, params) => {
  return {
    type: QUERY_COMPANY_CHECKLISTS,
    payload: APIUtils.get(`/companies/${companyId}/checklist?${qs.stringify(params)}`)
  };
};

export const createChecklist = (companyId, checklist) => {
  return {
    type: CREATE_COMPANY_CHECKLIST,
    payload: APIUtils.put(`/companies/${companyId}/checklist`, checklist)
  };
};

export const deleteChecklist = (companyId, checklistID) => ({
    type: DELETE_COMPANY_CHECKLIST,
    payload: APIUtils.del(
        `/companies/${companyId}/checklist/${checklistID}`
    )
});
