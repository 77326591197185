import APIUtils from "../../../utils/APIUtils";
import qs from "qs";
import _ from "lodash";
import { push } from "connected-react-router";

export const QUERY_PUBLICATIONS = "QUERY_PUBLICATIONS";
export const QUERY_COMPANY_PUBLICATION_CHILDREN =
	"QUERY_COMPANY_PUBLICATION_CHILDREN";
export const GET_PUBLICATION = "GET_PUBLICATION";
export const PUBLICATION_QUERY_FILES = "PUBLICATION_QUERY_FILES";
export const GET_FILE_CONTENT = "GET_FILE_CONTENT";
export const UPDATE_FILE_CONTENT = "UPDATE_FILE_CONTENT";
export const UPDATE_SEARCH_ABLE = "UPDATE_SEARCH_ABLE";
export const UPDATE_PUBLICATION = "UPDATE_PUBLICATION";
export const GET_PUBLICATION_FORM = "GET_PUBLICATION_FORM";
export const FORM_LOAD_DEPENDS = "FORM_LOAD_DEPENDS";
export const CREATE_PUBLICATION_FORM = "CREATE_PUBLICATION_FORM";
export const GET_PUBLICATION_FORM_SUMMARY = "GET_PUBLICATION_FORM_SUMMARY";
export const GET_PUBLICATION_FORM_COUNT = "GET_PUBLICATION_FORM_COUNT";
export const QUERY_FORM_SINGLE_SUBMISSIONS = "QUERY_FORM_SINGLE_SUBMISSIONS";
export const COMPANY_FORM_ADD_QUESTION = "COMPANY_FORM_ADD_QUESTION";
export const COMPANY_FORM_REMOVE_QUESTION = "COMPANY_FORM_REMOVE_QUESTION";
export const COMPANY_FORM_SAVE_QUESTION = "COMPANY_FORM_SAVE_QUESTION";
export const FORM_EDIT_QUESTION_SHOW = "FORM_EDIT_QUESTION_SHOW";
export const FORM_EDIT_QUESTION_HIDE = "FORM_EDIT_QUESTION_HIDE";
export const CREATE_NEW_PUBLICATION = "CREATE_NEW_PUBLICATION";
export const UPDATE_MASSEDIT = "UPDATE_MASSEDIT";
export const SHOW_ACTIVATE_FORM = "SHOW_ACTIVATE_FORM";
export const HIDE_ACTIVATE_FORM = "HIDE_ACTIVATE_FORM";
export const QUERY_COMPANY_PUBLICATION_DRAFTS =
	"QUERY_COMPANY_PUBLICATION_DRAFTS";
export const COMPANY_PUBLICATION_DELETE = "COMPANY_PUBLICATION_DELETE";
export const PUBLICATION_FORM_DEFAULT = "PUBLICATION_FORM_DEFAULT";
export const COMPANY_FORM_CLOSE_QUESTION = "COMPANY_FORM_CLOSE_QUESTION";
export const PUBLICATION_FORM_UPDATE = "PUBLICATION_FORM_UPDATE";
export const FORM_NEXT_STEP = "FORM_NEXT_STEP";
export const FORM_ACTIVATE_DATA_UPDATE = "FORM_ACTIVATE_DATA_UPDATE";
export const SAVE_FORM_ACTION = "SAVE_FORM_ACTION";
export const GET_FORM_DASHBOARD = "GET_FORM_DASHBOARD";
export const LIMIT = 20;

export const DEFAULT_FORM = {
	anonymous: false,
	requiredIdentification: [],
	active: false,
	sign: false,
	secondarySign: false,
	questions: [],
	webHooks: [],
	thankMessage: "",
	multiSign: false,
	useBankId: false,
	meta: {},
};

export const DEFAULT_QUESTION = {
	title: "",
	addToPortal: false,
	description: "",
	preferredLayout: "VERTICAL",
	preferredWidth: "FULL",
	type: false,
	options: null,
	required: false,
	searchable: false,
	meta: {},
};

export const POLAR_OPTIONS = [
	{ value: "POLAR_YES", label: "Yes" },
	{ value: "POLAR_NO", label: "No" },
];

export const convertRadioToPolar = (question) => {
	if (
		question.type === "RADIO" &&
		_.isEqual(question.options, POLAR_OPTIONS)
	) {
		question.type = "POLAR";
	}

	return question;
};

export const convertPolarToRadio = (question) => {
	if (question.type !== "POLAR") {
		return question;
	}

	question = Object.assign(question);

	question.type = "RADIO";

	return question;
};

export const queryPublications = (companyId, params) => ({
	type: QUERY_PUBLICATIONS,
	payload: APIUtils.get(
		`/companies/${companyId}/publications?` +
			qs.stringify({
				...params,
				limit: LIMIT + 1,
				offset: params.page ? parseInt(params.page, 10) * LIMIT : 0,
			})
	),
});

export const queryPublicationChildren = (companyId, params = {}) => {
	if (!params.limit || params.limit < 1) {
		params.limit = LIMIT;
	}
	if (!params.offset || params.offset < 0) {
		params.offset = 0;
	}
	let parent = "";
	if (params.parentId) {
		parent = "&parentId=" + params.parentId;
	}

	return {
		type: QUERY_COMPANY_PUBLICATION_CHILDREN,
		payload: APIUtils.get(
			`/companies/${companyId}/publications?limit=${params.limit}&offset=${params.offset}${parent}`
		),
	};
};

export const getPublication = (companyId, publicationId) => ({
	type: GET_PUBLICATION,
	payload: APIUtils.get(
		`/companies/${companyId}/publications/${publicationId}`
	),
});

export const updatePublication = (publication) => ({
	type: UPDATE_PUBLICATION,
	payload: APIUtils.put(
		`/companies/${publication.companyId}/publications/${publication.id}`,
		publication
	),
});

export const queryFiles = (companyId, publicationId, params) => {
	let queryStr = `?visible=true`;
	if (params.limit) {
		queryStr += `&limit=${params.limit}`;
	}
	if (params.offset || params.offset === 0) {
		queryStr += `&offset=${params.offset}`;
	}
	if (params.q) {
		queryStr += `&q=${params.q}&searchAble=true`;
	}
	return {
		type: PUBLICATION_QUERY_FILES,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/${publicationId}/files${queryStr}`
		),
	};
};

export const updateSearchable = (
	companyId,
	publicationId,
	fileId,
	searchAble
) => ({
	type: UPDATE_SEARCH_ABLE,
	payload: APIUtils.put(
		`/companies/${companyId}/publications/${publicationId}/files/${fileId}/search-able`,
		searchAble
	),
});

export const getPublicationForm = (companyId, formId) => ({
	type: GET_PUBLICATION_FORM,
	payload: APIUtils.get(`/companies/${companyId}/forms/${formId}`),
});

export const getPublicationFormSummary = (companyId, formId, params) => {
	let newParams = {};
	if (params.text) {
		newParams.q = params.text;
	}
	if (params.start || params.end) {
		newParams.span =
			(params.start ? params.start : "") +
			"," +
			(params.end ? params.end : "");
	}

	return {
		type: GET_PUBLICATION_FORM_SUMMARY,
		payload: APIUtils.get(
			`/companies/${companyId}/forms/${formId}/summary?${qs.stringify(
				newParams
			)}`
		),
	};
};

export const getPublicationFormCount = (companyId, formId, params) => {
	let newParams = {};
	if (params.text) {
		newParams.q = params.text;
	}
	if (params.start || params.end) {
		newParams.span =
			(params.start ? params.start : "") +
			"," +
			(params.end ? params.end : "");
	}

	return {
		type: GET_PUBLICATION_FORM_COUNT,
		payload: APIUtils.get(
			`/companies/${companyId}/forms/${formId}/count?${qs.stringify(
				newParams
			)}`
		),
	};
};

export const queryFormDepends = (company, publicationId) => (dispatch) => {
	let products = false;
	let customers = false;
	let customerCategories = false;
	let categories = false;
	let pricelists = false;

	if (company.enabledFeatures.products) {
		// products = APIUtils.get(
		// 	"/companies/" + company.id + "/products?limit=1000000&status=active"
		// ).then((r) => r.body);
		categories = APIUtils.get(
			"/companies/" + company.id + "/products/categories?limit=1000"
		).then((r) => r.body);
		pricelists = APIUtils.get(
			"/companies/" + company.id + "/products/pricelists?limit=1000"
		).then((r) => r.body);
	}

	if (company.enabledFeatures.customers) {
		// customers = APIUtils.get(
		// 	"/companies/" + company.id + "/customers?limit=1000000"
		// ).then((r) => r.body);
		customerCategories = APIUtils.get(
			"/companies/" + company.id + "/customers/categories?limit=1000"
		).then((r) => r.body);
	}

	const file = APIUtils.get(
		"/companies/" +
			company.id +
			"/publications/" +
			publicationId +
			"/files?limit=1&visible=true"
	).then((r) => r.body);

	dispatch({
		type: FORM_LOAD_DEPENDS,
		payload: Promise.all([
			//products,
			//customers,
			customerCategories,
			categories,
			pricelists,
			file,
		]).then(([//products,
			//customers,
			customerCategories, categories, pricelists, file]) => ({
			//products,
			//customers,
			customerCategories,
			categories,
			pricelists,
			file,
		})),
	});
};

export const createPublicationForm = (companyId, form) => {
	let newForm = Object.assign({}, form);
	if (newForm.key) {
		delete newForm.key;
	}
	return {
		type: CREATE_PUBLICATION_FORM,
		payload: APIUtils.post(`/companies/${companyId}/forms`, newForm),
	};
};

export const queryFormSingleSubmissions = (companyId, formId, params = {}) => {
	let newParams = {};
	if (params.text) {
		newParams.q = params.text;
	}
	if (params.start || params.end) {
		newParams.span =
			(params.start ? params.start : "") +
			"," +
			(params.end ? params.end : "");
	}

	return {
		type: QUERY_FORM_SINGLE_SUBMISSIONS,
		payload: APIUtils.get(
			"/companies/" +
				companyId +
				"/forms/" +
				formId +
				"/data?" +
				qs.stringify(
					Object.assign({}, newParams, {
						limit: LIMIT + 1,
						offset: params.page
							? parseInt(params.page, 10) * 20
							: 0,
					})
				)
		),
	};
};

export const createPublication = (companyId, publication) => ({
	type: CREATE_NEW_PUBLICATION,
	payload: APIUtils.post(`/companies/${companyId}/publications`, publication),
});

export const updateMassedit = (companyId, massedit) => ({
	type: UPDATE_MASSEDIT,
	payload: APIUtils.put(
		`/companies/${companyId}/publications/mass-edit`,
		massedit
	),
});

export const queryDrafts = (companyId, params = {}) => {
	if (!params.limit || params.limit < 1) {
		params.limit = LIMIT;
	}
	if (!params.offset || params.offset < 0) {
		params.offset = 0;
	}
	let type = "";
	if (params.type) {
		type = "&type=" + params.type;
	}
	let priority = "";
	if (params.priorityId) {
		priority = "&priorityId=" + params.priorityId;
	}

	return {
		type: QUERY_COMPANY_PUBLICATION_DRAFTS,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/drafts?limit=${params.limit}&offset=${params.offset}${type}${priority}`
		),
	};
};

export const deletePublication = (companyId, publicationId) => ({
	type: COMPANY_PUBLICATION_DELETE,
	payload: APIUtils.del(
		`/companies/${companyId}/publications/${publicationId}`
	),
});

export const getFormDashboard = (companyId) => ({
	type: GET_FORM_DASHBOARD,
	payload: APIUtils.get(
		`/companies/${companyId}/publications/form-dashboard`
	),
});

export const goToPublicationDetail = (publication, pathname) => (dispatch) => {
	if (
		publication.features.formActived === "false" ||
		!!publication.features.formActived === false
	) {
		dispatch(
			push({
				pathname: pathname + "/" + publication.id + "/detail",
			})
		);
	} else {
		dispatch(
			push({
				pathname: pathname + "/" + publication.id + "/form-submit",
			})
		);
	}
};
