import {
  QUERY_COMPANY_DEVIATIONS,
  GET_COMPANY_DEVIATION,
  QUERY_COUNT_COMPANY_DEVIATIONS,
  QUERY_SUM_COST_COMPANY_DEVIATIONS,
  GET_SUM_COUNT_COMPANY_DEVIATIONS,
  QUERY_COMPANY_DEVIATION_WATCHERS,
  GET_COMPANY_DEVIATION_WATCHER,
  LIMIT
} from "../../../actions/company/deviations/CompanyDeviationActions";

const Deviation = (
  state = {
    deviations: [], 
    deviation: null, 
    sumCostDeviations: [],
    sumCountDeviations: null, 
    countDeviations: [], 
    categories: [], 
    statuses: [],
    deviationWatchers: [],
    hasMore: false,
    deviationWatcher: null
  }, action) => {
  switch (action.type) {
    case QUERY_COMPANY_DEVIATIONS + "_FULFILLED":
      return {
        ...state,
        deviations: action.payload.body
      };
    case GET_COMPANY_DEVIATION + "_FULFILLED":
      return {
        ...state,
        deviation: action.payload.body
      };
    case QUERY_COUNT_COMPANY_DEVIATIONS + "_FULFILLED":
      return {
        ...state,
        countDeviations: action.payload.body
      };
    case QUERY_SUM_COST_COMPANY_DEVIATIONS + "_FULFILLED":
      return {
        ...state,
        sumCostDeviations: action.payload.body
      };
    case GET_SUM_COUNT_COMPANY_DEVIATIONS + "_FULFILLED":
      return {
        ...state,
        sumCountDeviations: action.payload.body
      };
    case "QUERY_DEVIATION_CATEGORIES":
      return {
        ...state,
        categories: action.data
      };
    case "QUERY_DEVIATION_STATUSES":
      return {
        ...state,
        statuses: action.data
      };
    case QUERY_COMPANY_DEVIATION_WATCHERS + "_FULFILLED":
      return {
        ...state,
        deviationWatchers: action.payload.body.slice(0, LIMIT),
        hasMore: action.payload.body.length > LIMIT
      };
    case GET_COMPANY_DEVIATION_WATCHER + "_FULFILLED":
      return {
        ...state,
        deviationWatcher: action.payload.body
      };

    default:
      return state || {};
  }
};

export default Deviation;
