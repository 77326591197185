import {
  QUERY_COMPANY_TASKS,
  GET_TASK,
  QUERY_TASK_TYPES,
  QUERY_TASK_STATUSES,
  QUERY_TASK_STATUSES_TOMAP,
  QUERY_TASK_CATEGORIES,
  QUERY_TASK_EMAILS,
  GET_MAIL_TO_TASK
} from "../../../actions/company/tasks/CompanyTaskActions";
import { toMap } from "../../../utils/toMap";
const LIMIT = 20;

const CompanyTask = (state = {tasks: [], taskTypes: [], taskStatuses: [], taskCategories: [], taskEmails: [], hasMore: false, task: null, emailToTask: null}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_TASKS + "_FULFILLED":
      return {
        ...state,
        tasks: action.payload.body
      };
    case GET_TASK + "_FULFILLED":
        return {
            ...state,
            task: action.payload.body
        };
    case QUERY_TASK_TYPES + "_FULFILLED":
      return {
        ...state,
        taskTypes: action.payload.body
      };
    case QUERY_TASK_STATUSES + "_FULFILLED":
        return {
            ...state,
            taskStatuses: action.payload.body
        };
    case QUERY_TASK_STATUSES_TOMAP + "_FULFILLED":
        return {
            ...state,
            taskStatuses: toMap(action.payload.body)
        };
    case QUERY_TASK_CATEGORIES + "_FULFILLED":
        return {
            ...state,
            taskCategories: action.payload.body
        };
    case QUERY_TASK_EMAILS + "_FULFILLED":
        return {
            ...state,
            taskEmails: action.payload.body.slice(0, LIMIT),
            hasMore: action.payload.body.length > LIMIT
        };
    case GET_MAIL_TO_TASK + "_FULFILLED":
        return {
            ...state,
            emailToTask: action.payload.body
        };
    case "GET_LOCATION_SEARCH":
        return {
            ...state,
            locationSearch: action.message
        };

    default:
      return state || {};
  }
};

export default CompanyTask;
