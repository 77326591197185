import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_USER_ROLES = "QUERY_COMPANY_USER_ROLES";
export const GET_COMPANY_USER_ROLE = "GET_COMPANY_USER_ROLE";
export const UPDATE_COMPANY_USER_ROLE = "UPDATE_COMPANY_USER_ROLE";
export const QUERY_COMPANY_ROLE_USERS = "QUERY_COMPANY_ROLE_USERS";
export const CREATE_COMPANY_USER_TO_ROLE = "CREATE_COMPANY_USER_TO_ROLE";
export const DELETE_COMPANY_USER_TO_ROLE = "DELETE_COMPANY_USER_TO_ROLE";

export const queryUserRoles = (companyId, params = {}) => {
  return {
    type: QUERY_COMPANY_USER_ROLES,
    payload: APIUtils.get(`/companies/${companyId}/user-roles?${qs.stringify({
      ...params,
    })}`)
  };
};

export const getUserRole = (companyId, roleId) => async (dispatch) => {
  const role = await APIUtils.get(`/companies/${companyId}/user-roles/${roleId}`);
  if (role.body && role.body.template && role.body.template.id && role.body.template.data) {
    await Promise.all(role.body.template.data.map((v, k) => {
      if (v.isChart && v.chartData) {
        if (v.chartData.type === "line-bar") {
          role.body.template.data[k].options = {
            responsive: true,
            legend: {
              display: true,
              position: "bottom"
            },
            tooltips: {
              mode: "label"
            },
            elements: {
              line: {
                fill: false
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: true
                  }
                }
              ],
              yAxes: []
            },
          };
          v.chartData.colors.map((value, key) => {
            role.body.template.data[k].options.scales.yAxes.push(
              {
                id: "y-axis-" + (key + 1),
                display: true,
                position: key === 0 ? "left" : "right",
                gridLines: {
                  display: false
                }
              }
            );
          });
        } else {
          role.body.template.data[k].options = {
            responsive: true,
            legend: {
              display: true,
              position: "bottom"
            }
          };
          if (
            v.chartData.type === "bar" ||
            v.chartData.type === "line"
          ) {
            role.body.template.data[k].options = {
              responsive: true,
              tooltips: {
                mode: "label"
              },
              legend: {
                display: true,
                position: "bottom"
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      display: true
                    }
                  }
                ]
              }
            };
          }
        }
      }
      if (v.columnType === "BLOG" && v.columnData) {
        let query = {
          only: v.columnData.only,
          limit: v.columnData.showThreePosts ? 3 : 1,
        };
        return APIUtils.get(
          `/companies/${companyId}/publications/${
            v.columnData.object
              ? v.columnData.object.id
              : v.columnData.blogId
          }/posts/lastest?${qs.stringify(query)}`
        ).then((resp) => {
          role.body.template.data[k].blogLastest = resp.body;
        });
      }
    }));
  }
  return dispatch({
    type: GET_COMPANY_USER_ROLE,
    payload: role.body
  });
};

export const updateUserRole = (companyId, role) => {
  return {
    type: UPDATE_COMPANY_USER_ROLE,
    payload: APIUtils.put(`/companies/${companyId}/user-roles/${role.id}`, role)
  };
};

export const queryRoleUsers = (companyId, roleId) => {
  return {
    type: QUERY_COMPANY_ROLE_USERS,
    payload: APIUtils.get(`/companies/${companyId}/user-roles/${roleId}/users?all=true`)
  };
};

export const addMemberToUserRole = (companyId, roleId, user) => {
  return {
    type: CREATE_COMPANY_USER_TO_ROLE,
    payload: APIUtils.post(`/companies/${companyId}/user-roles/${roleId}/users`, user)
  };
};

export const deleteMemberUserRole = (companyId, roleId, useId) => {
  return {
    type: DELETE_COMPANY_USER_TO_ROLE,
    payload: APIUtils.del(`/companies/${companyId}/user-roles/${roleId}/users/${useId}`)
  };
};
