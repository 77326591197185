import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_PUBLICATION_ESIGNS = "QUERY_COMPANY_PUBLICATION_ESIGNS";

export const queryPublicationEsigns = (companyId, publicationId, params = {}) => {
	return {
		type: QUERY_COMPANY_PUBLICATION_ESIGNS,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/${publicationId}/shares/e-signs?${qs.stringify(params)}`
		),
	};
};
