import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_CATEGORIES = "QUERY_COMPANY_CATEGORIES";
export const GET_COMPANY_CATEGORIES = "GET_COMPANY_CATEGORIES";

export const queryCategories = (companyId, params) => {
  return {
    type: QUERY_COMPANY_CATEGORIES,
    payload: APIUtils.get(`/companies/${companyId}/categories?${params?qs.stringify(params):""}`)
  };
};

export const getCategory = (companyId, categoryId) => {
  return {
    type: GET_COMPANY_CATEGORIES,
    payload: APIUtils.get(`/companies/${companyId}/categories/${categoryId}`)
  };
};