import Root from "./root";
import Login from "./auth";
import loading from "./loader";
import uploadEnQueueFiles from "./upload";
import UserProfile from "./user/profile";
import Company from "./company/company";
import CompanyUser from "./company/user/user";
import UserRole from "./company/user-roles/CompanyUserRoleReducer";
import Category from "./company/categories/CompanyCategoryReducer";
import Group from "./company/groups/CompanyGroupReducer";
import Message from "./company/messages/CompanyMessageReducer";
import Comment from "./company/comments/CompanyCommentReducer";
import CompanyTask from "./company/tasks/CompanyTaskReducer";
import Publication from "./company/publications/CompanyPublicationReducer";
import PublicationFile from "./company/publications/CompanyPublicationFileReducer";
import PublicationEsign from "./company/publications/CompanyPublicationEsignReducer";
import PublicationPost from "./company/publications/CompanyPublicationPostReducer";
import Checklist from "./company/checklists/CompanyChecklistReducer";
import Deviation from "./company/deviations/CompanyDeviationReducer";
import CompanyUnit from "./company/units/CompanyUnitReducers";
import Auth from "./auth";
import Profiles from "./user/profiles";

export default {
	loading,
	uploadEnQueueFiles,
	Root,
	Login,
	Company,
	UserProfile,
	CompanyUser,
	UserRole,
	Category,
	Group,
	Message,
	Comment,
	CompanyTask,
	Publication,
	PublicationFile,
	PublicationEsign,
	PublicationPost,
	Checklist,
	Deviation,
	CompanyUnit,
	Auth,
	Profiles
};
