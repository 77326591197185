import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_PUBLICATIONS = "QUERY_COMPANY_PUBLICATIONS";
export const QUERY_COMPANY_DRAFTS = "QUERY_COMPANY_DRAFTS";
export const GET_COMPANY_PUBLICATION = "GET_COMPANY_PUBLICATION";
export const UPDATE_COMPANY_PUBLICATION = "UPDATE_COMPANY_PUBLICATION";
export const LOCK_FILE_UPLOAD_COMPANY_PUBLICATION = "LOCK_FILE_UPLOAD_COMPANY_PUBLICATION";
export const UNLOCK_FILE_UPLOAD_COMPANY_PUBLICATION = "UNLOCK_FILE_UPLOAD_COMPANY_PUBLICATION";
export const CREATE_COMPANY_PUBLICATION = "CREATE_COMPANY_PUBLICATION";
export const QUERY_PUBLICATION_WATCHERS = "QUERY_PUBLICATION_WATCHERS";
export const CREATE_PUBLICATION_WATCHER = "CREATE_PUBLICATION_WATCHER";
export const DELETE_PUBLICATION_WATCHER = "DELETE_PUBLICATION_WATCHER";
export const UPDATE_PUBLICATION_PLANT = "UPDATE_PUBLICATION_PLANT";
export const CREATE_PUBLICATION_INVESTIGATION_USER = "CREATE_PUBLICATION_INVESTIGATION_USER";
export const UPDATE_PUBLICATION_ORDER = "UPDATE_PUBLICATION_ORDER";
export const UPDATE_PUBLICATION_ORDER_SUBCRIPTION = "UPDATE_PUBLICATION_ORDER_SUBCRIPTION";
export const GET_COMPANY_PUBLICATION_KEYPASS = "GET_COMPANY_PUBLICATION_KEYPASS";
export const UPDATE_COMPANY_PUBLICATION_KEYPASS = "UPDATE_COMPANY_PUBLICATION_KEYPASS";
export const LIMIT = 20;

export const queryPublications = (companyId, params = {}) => {
	let query = {
		...params,
		limit: LIMIT + 1,
	};

	delete query.page;

	query.offset = (parseInt(params.page, 10) | 0) * LIMIT;

	return {
		type: QUERY_COMPANY_PUBLICATIONS,
		payload: APIUtils.get(
			`/companies/${companyId}/publications?${qs.stringify({
				...query,
			})}`
		),
	};
};

export const queryDrafts = (companyId, params = {}) => {
	let query = {
		...params,
		limit: LIMIT + 1,
	};
	delete query.page;
	query.offset = (parseInt(params.page, 10) | 0) * LIMIT;
	return {
		type: QUERY_COMPANY_DRAFTS,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/drafts?${qs.stringify({
				...query,
			})}`
		),
	};
};

export const queryStarred = (companyId, userId, params = {}) => {
	let query = {
		...params,
		limit: LIMIT + 1,
	};
	delete query.page;
	query.offset = (parseInt(params.page, 10) | 0) * LIMIT;
	return {
		type: QUERY_COMPANY_DRAFTS,
		payload: APIUtils.get(
			`/companies/${companyId}/users/${userId}/starred?${qs.stringify({
				...query,
			})}`
		),
	};
};

export const getPublication = (companyId, publicationId) => {
	return {
		type: GET_COMPANY_PUBLICATION,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/${publicationId}`
		),
	};
};

export const createPublication = (companyId, publication) => {
    return {
        type: CREATE_COMPANY_PUBLICATION,
        payload: APIUtils.post(
            `/companies/${companyId}/publications`, publication
        ),
    };
};

// export const updatePublication = (companyId, publication) => {
// 	return {
// 		type: UPDATE_COMPANY_PUBLICATION,
// 		payload: APIUtils.put(
// 			`/companies/${companyId}/publications/${publication.id}`, publication
// 		),
// 	};
// };

export const updatePublication = (companyId, publication) => ({
	type: UPDATE_COMPANY_PUBLICATION,
	payload: APIUtils.put(
		`/companies/${companyId}/publications/${publication.id}`, publication
	)
});

export const lockFileUpload = (companyId, publicationId) => {
	return {
		type: LOCK_FILE_UPLOAD_COMPANY_PUBLICATION,
		payload: APIUtils.put(
			`/companies/${companyId}/publications/${publicationId}/upload-lock`, {}
		),
	};
};

export const unlockFileUpload = (companyId, publicationId) => {
	return {
		type: UNLOCK_FILE_UPLOAD_COMPANY_PUBLICATION,
		payload: APIUtils.del(
			`/companies/${companyId}/publications/${publicationId}/upload-lock`
		),
	};
};

export const queryPublicationWatchers = (companyId, publicationId) => {
	return {
		type: QUERY_PUBLICATION_WATCHERS,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/${publicationId}/watchers`
		),
	};
};

export const createPublicationWatcher = (companyId, publicationId, watcher) => {
	return {
		type: CREATE_PUBLICATION_WATCHER,
		payload: APIUtils.post(
			`/companies/${companyId}/publications/${publicationId}/watchers`, watcher
		),
	};
};

export const deletePublicationWatcher = (companyId, publicationId, watcherId) => {
	return {
		type: DELETE_PUBLICATION_WATCHER,
		payload: APIUtils.del(
			`/companies/${companyId}/publications/${publicationId}/watchers/${watcherId}`
		),
	};
};

export const updatePublicationPlant = (companyId, publicationId, plant) => {
    return {
        type: UPDATE_PUBLICATION_PLANT,
        payload: APIUtils.put(
            `/companies/${companyId}/publications/${publicationId}/plants`, plant
        ),
    };
};

export const createPublicationInvestigationUser = (companyId, publicationId, user) => {
    return {
        type: CREATE_PUBLICATION_INVESTIGATION_USER,
        payload: APIUtils.post(
            `/companies/${companyId}/publications/${publicationId}/investigation/users`, user
        ),
    };
};

export const updatePublicationOrder = (companyId, publicationId, order) => {
    return {
        type: UPDATE_PUBLICATION_ORDER,
        payload: APIUtils.put(
            `/companies/${companyId}/publications/${publicationId}/orders`, order
        ),
    };
};

export const updatePublicationOrderSubcription = (companyId, publicationId, order) => {
    return {
        type: UPDATE_PUBLICATION_ORDER_SUBCRIPTION,
        payload: APIUtils.put(
            `/companies/${companyId}/publications/${publicationId}/subcriptions`, order
        ),
    };
};

export const getPublicationKeypass = (companyId, publicationId) => {
	return {
		type: GET_COMPANY_PUBLICATION_KEYPASS,
		payload: APIUtils.get(
			`/companies/${companyId}/publications/${publicationId}/keypass`
		),
	};
};

export const updateKeypass = (companyId, publicationId, keypass) => {
	return {
		type: UPDATE_COMPANY_PUBLICATION_KEYPASS,
		payload: APIUtils.post(
			`/companies/${companyId}/publications/${publicationId}/keypass`, keypass
		),
	};
};