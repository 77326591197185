import {
    QUERY_COMPANY_UNITS
} from "../../../actions/company/units/CompanyUnitActions";

const CompanyUnit = (
    state = { units: [] },
    action
) => {
    switch (action.type) {
        case QUERY_COMPANY_UNITS + "_FULFILLED":
            return {
                ...state,
                units: action.payload.body
            };

        default:
            return state;
    }
};

export default CompanyUnit;

