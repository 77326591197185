import {
  QUERY_COMPANY_PUBLICATION_POSTS
} from "../../../actions/company/publications/CompanyPublicationPostActions";

const PublicationPost = (state = { posts: [] }, action) => {
  switch (action.type) {
    case QUERY_COMPANY_PUBLICATION_POSTS + "_FULFILLED":
      return {
        ...state,
        posts: action.payload.body
      };

    default:
      return state || {};
  }
};

export default PublicationPost;
