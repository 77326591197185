import qs from "qs";
import APIUtils from "../../utils/APIUtils";

export const QUERY_PROFILES = "QUERY_PROFILES";

export const queryProfiles = (companyId, params = {}) => {
    if (!params.limit) {
        params.limit = 100;
    }
    if (!params.offset) {
        params.offset = 0;
    }

    return {
        type: "QUERY_PROFILES",
        payload: APIUtils.get(
            "/companies/" + companyId + "/users?" + qs.stringify(params)
        ),
    };
};
