import {
  QUERY_COMPANY_COMMENTS
} from "../../../actions/company/comments/CompanyCommentActions";

const Comment = (state = {comments: []}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_COMMENTS + "_FULFILLED":
      return {
        ...state,
        comments: action.payload.body
      };

    default:
      return state || {};
  }
};

export default Comment;
