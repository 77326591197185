import { USER_LOGIN, USER_LOGOUT } from "../actions/auth";

const Auth = (
  state = {
    pending: false,
    token: window.localStorage.token || null,
    userId: window.localStorage.userId || null,
    msg: "",
    isLogin: false
  },
  action
) => {
  switch (action.type) {
    case USER_LOGIN + "_PENDING":
      return {
        ...state,
        pending: true
      }
    case USER_LOGIN + "_FULFILLED":
      if (window.localStorage) {
        window.localStorage.setItem("token", action.payload.body.token);
        window.localStorage.setItem("userId", action.payload.body.userId);
      }
      return {
        ...state,
        token: action.payload.body.token,
        userId: action.payload.body.userId,
        msg: "",
        padding: false,
      };
    case USER_LOGIN + "_REJECTED":
      if (window.localStorage) {
        delete window.localStorage.token;
        delete window.localStorage.userId;
      }
      return {
        ...state,
        token: null,
        userId: null,
        code: action.payload.body.code,
        phone: action.payload.body.phone,
        pending: false
      };

    case USER_LOGOUT:
      if (window.localStorage) {
        delete window.localStorage.token;
        delete window.localStorage.userId;
      }
      return {
        ...state,
        userId: null,
        token: null,
      }

    case "HISTORY_ISLOGIN_SUCCESS":
      return {
        ...state,
        isLogin: action.msg,
      };

    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        msg: action.msg,
      };

    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default Auth;
