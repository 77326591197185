import {
  QUERY_COMPANY_CHECKLISTS
} from "../../../actions/company/checklists/CompanyChecklistActions";

const Checklist = (state = {checklists: []}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_CHECKLISTS + "_FULFILLED":
      return {
        ...state,
        checklists: action.payload.body
      };

    default:
      return state || {};
  }
};

export default Checklist;
