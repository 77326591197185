const config = {
	development: {
		API_BASE: "https://api.devnew.dokiv.se/v1",
		APP_URL: "https://app.devnew.dokiv.se",
		PORTAL_URL: "https://portal.devnew.dokiv.se",
		APP_ID: "3f49d195-e061-49ea-9ba8-987ded37c9e9"
	},
	production: {
		API_BASE: "https://api.dokiv.com/v1",
		APP_URL: "https://app.dokiv.com",
		PORTAL_URL: "https://portal.dokiv.com",
		APP_ID: "340c61b6-db42-4273-a91c-46e47ab94c14"
	}
};

const environment = process.env.NODE_ENV || 'development';

module.exports = config[environment];