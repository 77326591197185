import { QUERY_PROFILES } from "../../actions/user/profiles";

import uuid from "../../utils/uuid.js";

const Profiles = (state = { profiles: [], hasMore: false }, action) => {
    switch (action.type) {
        case QUERY_PROFILES + "_FULFILLED":
            return {
                ...state,
                profiles: action.payload.body.slice(0, 100),
                hasMore: action.payload.body.length > 100,
            };

        default:
            return state || {};
    }
};

export default Profiles;
