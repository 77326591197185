import {
  QUERY_COMPANY_PUBLICATIONS,
  QUERY_COMPANY_DRAFTS,
  GET_COMPANY_PUBLICATION,
  QUERY_PUBLICATION_WATCHERS,
  LIMIT,
} from "../../../actions/company/publications/CompanyPublicationActions";

import {
  convertRadioToPolar,
  GET_PUBLICATION_FORM,
  PUBLICATION_FORM_DEFAULT,
} from "../../../actions/company/form/CompanyPublicationActions";

const DEFAULT_FORM = {
  anonymous: false,
  requiredIdentification: [],
  active: false,
  sign: false,
  secondarySign: false,
  questions: [],
  webHooks: [],
  thankMessage: "",
  multiSign: false,
  useBankId: false,
  meta: {},
};

const Publication = (state = { publications: [], hasMore: false, watchers: [], drafts: [] }, action) => {
  // console.log("action",state.form);
  switch (action.type) {
    case QUERY_COMPANY_PUBLICATIONS + "_FULFILLED":
      return {
        ...state,
        publications: action.payload.body.slice(0, LIMIT),
        hasMore: action.payload.body.length > LIMIT,
      };
    case QUERY_COMPANY_DRAFTS + "_FULFILLED":
      return {
        ...state,
        drafts: action.payload.body.slice(0, LIMIT),
        hasMore: action.payload.body.length > LIMIT,
      };
    case QUERY_PUBLICATION_WATCHERS + "_FULFILLED":
      return {
        ...state,
        watchers: action.payload.body
      };
    case GET_COMPANY_PUBLICATION + "_FULFILLED":
      return {
        ...state,
        publication: action.payload.body,
      };

    case GET_COMPANY_PUBLICATION + "_PENDING":
      return {
        ...state,
        publication: null,
      };

    case GET_PUBLICATION_FORM + "_PENDING":
    case PUBLICATION_FORM_DEFAULT:
      return {
        ...state,
        form: Object.assign({}, DEFAULT_FORM, { questions: [] }),
      };

    case GET_PUBLICATION_FORM + "_FULFILLED":
      action.payload.body.questions = action.payload.body.questions.map(
        convertRadioToPolar
      );
      return {
        ...state,
        form: action.payload.body,
      };

    default:
      return state || {};
  }
};

export default Publication;
