import config from "../config";
import { replace } from "connected-react-router";
import APIUtils from "../utils/APIUtils";
import i18n from "../i18n";
import qs from "qs";
import { history } from "../index";
import { toast } from 'react-toastify';

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const REDIRECT_PATTERN = /\/login|\/reset-password/i;

export const logout = () => (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
  });
  window.location = "/login";
};

export const login = (email, password) => ({
  type: USER_LOGIN,
  payload: APIUtils.post("/authorize?appId=" + config.APP_ID, {
    email, password
  })
});

const resetSuccess = () => ({
  type: "RESET_PASSWORD_SUCCESS",
  msg: i18n.t("user.request-password-reset-success"),
});

const resetFailure = () => ({
  type: "RESET_PASSWORD_FAILURE",
  msg: i18n.t("user.request-password-reset-failure"),
});

export const reset = (email) => dispatch => {
  APIUtils.post("/users/forgot-password", { Email: email }).then(
    () => {
      toast.success(i18n.t("user.request-password-reset-success"));
      dispatch(replace("/login"));
    },
    () => {
      toast.error(i18n.t("user.request-password-reset-failure"));
      dispatch(resetFailure());
    }
  );
};

export const verifySMS = (code) => (dispatch) => {
  APIUtils.post(`/authorize-sms?appId=${config.APP_ID}`, { sms: code }).then(
    (r) => {
      dispatch(loginSuccess(r.body.token, r.body.userId));
      const location = history.location;
      const query = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (REDIRECT_PATTERN.test(location.pathname)) {
        let redirect = "/";
        if (query.redirect && !REDIRECT_PATTERN.test(query.redirect)) {
          redirect = query.redirect;
        }

        window.location = redirect;
      }
    },
    () => {
      // dispatch(
      //   Notifications.error({
      //     title: "",
      //     message: T.translate("login.sms-wrong-code"),
      //     position: "tc",
      //     autoDismiss: 3
      //   })
      // );
    }
  );
};
