import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createHistory from "history/createBrowserHistory";
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter,
} from "connected-react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import reducers from "./reducers";
import {
  loadingBarReducer,
  loadingBarMiddleware,
} from "react-redux-loading-bar";
import { ToastContainer, Slide } from "react-toastify";
import "./styles/components/components.scss";
import "react-toastify/dist/ReactToastify.css";
import jQuery from "jquery";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    },
  },
})
window.jQuery = window.$ = jQuery;
window.fancybox = require("fancybox")(jQuery);

const App = lazy(() => import("./components/App"));
const Login = lazy(() => import("./pages/login/login"));
const ResetPassword = lazy(() => import("./pages/login/reset-password"));

export const history = createHistory();

const createRootReducer = (history) =>
  combineReducers({
    ...reducers,
    loadingBar: loadingBarReducer,
    router: connectRouter(history),
  });

export const store = createStore(
  createRootReducer(history), // root reducer with router state
  {},
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
      promise,
      loadingBarMiddleware()
    )
  )
);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ConnectedRouter history={history}>
          <>
            <Suspense fallback={<div></div>}>
              <Switch>
                <Route path="/app" component={App} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Redirect from="/" to="/app" />
              </Switch>
            </Suspense>
            <div style={{ position: "absolute" }}>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                transition={Slide}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </>
        </ConnectedRouter>
      </I18nextProvider>
    </Provider>
    {/* <ReactQueryDevtools initialIsOpen /> */}
  </QueryClientProvider>
);