import APIUtils from "../../../utils/APIUtils";
import qs from "qs";
export const LIMIT = 20;

export const QUERY_COMPANY_DEVIATIONS = "QUERY_COMPANY_DEVIATIONS";
export const CREATE_COMPANY_DEVIATION = "CREATE_COMPANY_DEVIATION";
export const GET_COMPANY_DEVIATION = "GET_COMPANY_DEVIATION";
export const QUERY_COUNT_COMPANY_DEVIATIONS = "QUERY_COUNT_COMPANY_DEVIATIONS";
export const QUERY_SUM_COST_COMPANY_DEVIATIONS = "QUERY_SUM_COST_COMPANY_DEVIATIONS";
export const GET_SUM_COUNT_COMPANY_DEVIATIONS = "GET_SUM_COUNT_COMPANY_DEVIATIONS";
export const QUERY_COMPANY_DEVIATION_WATCHERS = "QUERY_COMPANY_DEVIATION_WATCHERS";
export const DELETE_COMPANY_DEVIATION_WATCHER = "DELETE_COMPANY_DEVIATION_WATCHER";
export const CREATE_COMPANY_DEVIATION_WATCHER = "CREATE_COMPANY_DEVIATION_WATCHER";
export const GET_COMPANY_DEVIATION_WATCHER = "GET_COMPANY_DEVIATION_WATCHER";
export const UPDATE_COMPANY_DEVIATION_WATCHER = "UPDATE_COMPANY_DEVIATION_WATCHER";

export const queryDeviations = (companyId, params = {}) => {
	let url = `/companies/${companyId}/deviations`;
	let query = params;
	if (!params.limit) {
		query.limit = LIMIT + 1;
	}
	if (!params.offset) {
		query.offset = 0;
	}
	if (query) {
		url += `?` + qs.stringify(query);
	}
	return {
		type: QUERY_COMPANY_DEVIATIONS,
		payload: APIUtils.get(url),
	};
};

export const queryCountDeviations = (companyId, params = {}) => {
	let url = `/companies/${companyId}/deviations/count?${qs.stringify(params)}`;
	return {
		type: QUERY_COUNT_COMPANY_DEVIATIONS,
		payload: APIUtils.get(url),
	};
};

export const querySumCostDeviations = (companyId, params = {}) => {
	let url = `/companies/${companyId}/deviations/sum?${qs.stringify(params)}`;
	return {
		type: QUERY_SUM_COST_COMPANY_DEVIATIONS,
		payload: APIUtils.get(url),
	};
};

export const getSumCountDeviations = (companyId) => {
	let url = `/companies/${companyId}/deviations/sum-count`;
	return {
		type: GET_SUM_COUNT_COMPANY_DEVIATIONS,
		payload: APIUtils.get(url),
	};
};

export const getDeviation = (companyId, deviationId) => ({
	type: GET_COMPANY_DEVIATION,
	payload: APIUtils.get(`/companies/${companyId}/deviations/${deviationId}`)
});

export const createDeviation = (companyId, deviation) => ({
	type: CREATE_COMPANY_DEVIATION,
	payload: APIUtils.post(`/companies/${companyId}/deviations`, deviation)
});

export const queryDeviationWatchers = (companyId, params = {}) => {
	let url = `/companies/${companyId}/deviation-watchers`;
	let query = params;
	if (!params.limit) {
		query.limit = LIMIT + 1;
	}
	if (!params.offset) {
		query.offset = 0;
	}
	if (query) {
		url += `?` + qs.stringify(query);
	}
	return {
		type: QUERY_COMPANY_DEVIATION_WATCHERS,
		payload: APIUtils.get(url),
	};
};

export const deleteDeviationWatcher = (companyId, deviationId) => ({
	type: DELETE_COMPANY_DEVIATION_WATCHER,
	payload: APIUtils.del(`/companies/${companyId}/deviation-watchers/${deviationId}`)
});

export const createDeviationWatcher = (companyId, deviationWatcher) => ({
	type: CREATE_COMPANY_DEVIATION_WATCHER,
	payload: APIUtils.post(`/companies/${companyId}/deviation-watchers`, deviationWatcher)
});

export const getDeviationWatcher = (companyId, deviationWatcherId) => ({
	type: GET_COMPANY_DEVIATION_WATCHER,
	payload: APIUtils.get(`/companies/${companyId}/deviation-watchers/${deviationWatcherId}`)
});

export const updateDeviationWatcher = (companyId, deviationWatcher) => ({
	type: UPDATE_COMPANY_DEVIATION_WATCHER,
	payload: APIUtils.put(`/companies/${companyId}/deviation-watchers/${deviationWatcher.id}`, deviationWatcher)
});
