import {
  QUERY_COMPANY_GROUPS,
  QUERY_COMPANY_PORTALS
} from "../../../actions/company/groups/CompanyGroupActions";

const Group = (state = {groups: [], portals: []}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_GROUPS + "_FULFILLED":
      return {
        ...state,
        groups: action.payload.body
      };
    case QUERY_COMPANY_PORTALS + "_FULFILLED":
      return {
        ...state,
        portals: action.payload.body
      };

    default:
      return state || {};
  }
};

export default Group;
