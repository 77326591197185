import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_PUBLICATION_FILES = "QUERY_COMPANY_PUBLICATION_FILES";
export const GET_COMPANY_PUBLICATION_FILE_PREVIEW = "GET_COMPANY_PUBLICATION_FILE_PREVIEW";

export const queryPublicationFiles = (companyId, publicationId, params) => {
  return {
    type: QUERY_COMPANY_PUBLICATION_FILES,
    payload: APIUtils.get(`/companies/${companyId}/publications/${publicationId}/files?${qs.stringify(params)}&visible=true`)
  };
};

export const getFilePreview = (companyId, publicationId, fileId) => {
  return {
    type: GET_COMPANY_PUBLICATION_FILE_PREVIEW,
    payload: APIUtils.get(`/companies/${companyId}/publications/${publicationId}/files/${fileId}/preview`)
  };
};
