import {
  QUERY_COMPANY_USER_ROLES,
  GET_COMPANY_USER_ROLE,
  QUERY_COMPANY_ROLE_USERS
} from "../../../actions/company/user-roles/CompanyUserRoleActions";

const UserRole = (state = {userRoles: [], role: null, members: []}, action) => {
  switch (action.type) {
    case QUERY_COMPANY_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload
      };
    case GET_COMPANY_USER_ROLE:
      return {
        ...state,
        role: action.payload
      };
    case QUERY_COMPANY_ROLE_USERS:
      return {
        ...state,
        members: action.payload
      };

    default:
      return state || {};
  }
};

export default UserRole;
