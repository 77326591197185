import qs from "qs";
import APIUtils from "../../../utils/APIUtils";

export const QUERY_COMPANY_TASKS = "QUERY_COMPANY_TASKS";
export const GET_TASK = "GET_TASK";
export const QUERY_TASK_TYPES = "QUERY_TASK_TYPES";
export const QUERY_TASK_STATUSES = "QUERY_TASK_STATUSES";
export const QUERY_TASK_STATUSES_TOMAP = "QUERY_TASK_STATUSES_TOMAP";
export const QUERY_TASK_CATEGORIES = "QUERY_TASK_CATEGORIES";
export const QUERY_TASK_EMAILS = "QUERY_TASK_EMAILS";
export const GET_MAIL_TO_TASK = "GET_MAIL_TO_TASK";

export const queryTasks = (companyId, params) => {
  return {
    type: QUERY_COMPANY_TASKS,
    payload: APIUtils.get(`/companies/${companyId}/reminders?${qs.stringify(params)}`)
  };
};

export const getTask = (companyId, taskId) => {
    return {
        type: GET_TASK,
        payload: APIUtils.get(
            `/companies/${companyId}/reminders/${taskId}`
        )
    };
};

export const queryTaskTypes = (companyId) => {
  return {
      type: QUERY_TASK_TYPES,
      payload: APIUtils.get(
          `/companies/${companyId}/reminder-type`
      )
  };
};

export const queryTaskStatuses = (companyId, toMap) => {
    if(toMap){
        return {
            type: QUERY_TASK_STATUSES_TOMAP,
            payload: APIUtils.get(
                `/companies/${companyId}/reminder-status`
            )
        };
    }else{
        return {
            type: QUERY_TASK_STATUSES,
            payload: APIUtils.get(
                `/companies/${companyId}/reminder-status`
            )
        };
    }
};

export const queryTaskCategories = (companyId) => {
    return {
        type: QUERY_TASK_CATEGORIES,
        payload: APIUtils.get(
            `/companies/${companyId}/reminders/categories`
        )
    };
};

export const queryTaskEmails = (companyId, params) => {
    return {
        type: QUERY_TASK_EMAILS,
        payload: APIUtils.get(
            `/companies/${companyId}/task-emails?${qs.stringify(params)}`
        )
    };
};

export const getEmailToTask = (companyId, emailId) => {
    return {
        type: GET_MAIL_TO_TASK,
        payload: APIUtils.get(
            `/companies/${companyId}/task-emails/${emailId}`
        )
    };
};
