import { CHANGE_LANGUAGE, GET_USER } from "../actions/root";

const Root = (
  state = {
    langKey: 'se',
    currentUser: null,
    companies: [],
    selectedCompany: null,
  },
  action
) => {
  switch (action.type) {
    case "LOGIN_FAILURE":
    case GET_USER + "_PENDING":
      return {
        ...state,
        currentUser: null,
      };
    case GET_USER + "_FULFILLED":
      return {
        ...state,
        currentUser: action.payload.body,
      };
    case "QUERY_ALL_COMPANIES_PENDING":
      return {
        ...state,
        companies: [],
      };
    case "QUERY_ALL_COMPANIES_FULFILLED":
      return {
        ...state,
        companies: action.payload.body,
      };

    case "QUERY_NOTICES_FULFILLED":
      return {
        ...state,
        notices: action.payload.body,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        langKey: action.langKey,
      };

    case "SELECTED_COMPANY":
      return {
        ...state,
        selectedCompany: action.selectedCompany,
      };

    default:
      return state;
  }
};

export default Root;
